import React, { Component } from 'react';
import { connect } from 'react-redux';
import GalleryPreview from '../components/GalleryPreview';

import { reducers } from '../store/configurePresentStore';
import withReducers from '../store/withReducers';
import createPopup from '../popup-factory';
import { WebsocketContextProvider } from '../context/websocket-context';
import { getNotificationUserList } from '../helpers/chat';

const DEFAULT_COLOR = '#5ca3b6';

class PresentApp extends Component {
  render() {
    const { userIdentity, popups, order, items, notifyUser, tenantOptions } = this.props;
    return (
      <WebsocketContextProvider
        identity={userIdentity}
        notifyUser={notifyUser}
        tenantOptions={tenantOptions}
        defaultColor={DEFAULT_COLOR}
        canRemoteControl={false}
        tenantId={order.company_id}
        getBots={false}
      >
        <div>
          <GalleryPreview order={order} items={items} popups={popups} />
          {popups.map((p, idx) => createPopup(p, idx))}
          {popups.length ? <div className="reveal-overlay" tabIndex="-1" aria-hidden="true" style={{ display: 'block', overflow: 'hidden' }}></div> : undefined}
        </div>
      </WebsocketContextProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenantOptions: state.chat_options,
    popups: state.display.popups,
    userIdentity: state.identity,
    notifyUser: getNotificationUserList(state.chat_options?.chat_notification_user, state.sales_rep_id, state.client_rep_id)
  };
};

export default withReducers(connect(mapStateToProps)(PresentApp), reducers, true);
