import _ from 'lodash';
import URI from 'urijs';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';

import { createAddComment } from '../actions';
import { createAddTemp, createDeleteTemp } from '../actions/temp';
import { fetchProductItems } from '../actions/shop';

import { getFullOptionItem } from '../selectors';

import { formatMoney, parseMysqlDate, toTitleCase, sizeSort } from '../utils';
import ProductWarnings from './ProductWarning';
import { ItemWarning } from './ItemWarnings';
import Loading from './Loading';
import { colors } from '@commonsku/styles';

class GalleryText extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      comment: '',
      show_price_grid: false,
      cart: props.cart,
      windowWidth: props.windowWidth,
      windowHeight: props.windowHeight,
      loading: false
    };

    _.bindAll(this, ['handleTitleCase', 'handleAddComment', 'handleChangeComment', 'handleRemoveQuoteItem', 'returnToQuote']);
  }

  componentDidMount() {
    this.handleFetchProductItems(this.props.currentItemId);
    if(this.props.full_item) {
      this.props.grabComments(Object.values(this.props.full_item.comments));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.full_item) {
      this.props.grabComments(Object.values(this.props.full_item.comments));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name,
      cart: nextProps.cart,
      windowWidth: nextProps.windowWidth,
      windowHeight: nextProps.windowHeight
    });

    if(nextProps.currentItemId !== this.props.currentItemId) {
      this.handleFetchProductItems(nextProps.currentItemId);
    }

    if(nextProps.full_item.updated) {
      if(nextProps.full_item.options.filter(o => o.hidden != 1).length < 1 || nextProps.full_item.share_pricing == 0) {
        this.setState({ show_price_grid: false, loading: false });
      }else{
        this.setState({ show_price_grid: true, loading: false });
      }
    }
  }

  handleFetchProductItems(item_id) {
    const { order, onFetchProductItems } = this.props;

    this.setState({ loading: true }, function() {
      onFetchProductItems(item_id, order.order_id);
    });
  }

  handleTitleCase(str) {
     let splitStr = str.toLowerCase().split(', ');
     for (let i = 0; i < splitStr.length; i++) {
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
     }
     return splitStr.join(', ');
  }

  handleChangeComment(value) {
    this.setState({ comment: value });
  }

  handleAddComment(item_id) {
    this.props.onAddComment(item_id, this.state.name, this.state.comment);
    this.setState({ comment: '' });
  }

  handleRemoveQuoteItem(item_id) {
    this.props.onCreateDeleteTemp(item_id);
  }

  returnToQuote() {
    this.props.handleQuote();
    this.props.closeLightbox();
  }

  renderUpCharges() {
    const { full_item, items } = this.props;
    if (!full_item.item_retail_adjustments || !full_item.item_retail_adjustments.length) {
      return null;
    }

    const getDescription = adj => {
      if (!!adj.sku) {
        const sku = full_item.item_skus.filter(s => s.product_sku_id === adj.sku)[0];
        return !!sku.description ? (sku.description + (!!sku.sku_description ? (' (' + sku.sku_description + ')'): '')) : sku.sku_description;
      } else {
        return adj.axis;
      }
    };
    return (
      <div>
        <h5 style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>Up Charges</h5>
        {full_item.item_retail_adjustments.map((a, i) =>
          <span key={a.item_retail_adjustment_id}>
            {0 !== i && ', '}
            {getDescription(a)}: +${formatMoney(a.adjustment)}
          </span>
        )}
      </div>
    );
  }

  render() {
    const {
      currentItemId,
      currentItemType,
      handleChangeName,
      name,
      isImage,
      currentTab,
      grabComments,
      handleQuote,
      closeLightbox,
      full_item,
      order,
      company_name,
      username,
      company_address,
      cart,
      sales_order_checked_out,
      onAddComment,
      onCreateAddTemp,
      onCreateDeleteTemp,
      onCreateAddToQuotePopup,
      windowHeight,
      windowWidth,
      handleSwitchTab,
      show_product_warnings
    } = this.props;
    const isVisibleFixedCost = ic =>
      0 == ic.hidden && null !== ic.quantity && (
      ic.item_cost_tab !== 'INTERNATIONAL' ||
      (ic.quantity != 1 && ic.quantity != null) ||
      (ic.quantity == null && ic.percent != 0) ||
      ic.unit_cost != 0 ||
      ic.unit_price != 0 ||
      !ic.from_db);
    const fixed_costs = (full_item && full_item.fixed_costs ? full_item.fixed_costs : full_item && full_item.item_costs ? full_item.item_costs : []).filter(isVisibleFixedCost);

    const comment_disabled = this.state.name == '' ? true : false;
    const post_disabled = this.state.name == '' || this.state.comment == '' ? true : false;
    const rep_comment_style = {
      background: '#FDFFE5'
    };
    const order_button_style = {
      position: 'fixed',
      width: '18%',
      margin: '10px',
      bottom: '20px',
      right: '20%',
      height: '57px',
      opacity: 0.75,
    };

    let url = URI();
    let portal = url.search(true).portal;
    if(portal) {
      order_button_style.marginBottom = '100px';
    }

    const sku_options = full_item.item_skus.reduce(
      (t, item_sku) => ({
        ...t,
        ...item_sku.options.reduce(
          (o, option) => ({
            ...o,
            [option.option_axis]: Array.from(new Set((t[option.option_axis] || []).concat(option.option_name))).sort(
              'size' === option.option_axis ? sizeSort : undefined
            )
          }),
          {}
        )
      }),
      {}
    );

    const axes = Object.keys(sku_options).filter(a => 'dimension' !== a || (sku_options[a].length > 1 && !sku_options['size'])).sort();

    return (
      <div className={!isImage ? "gallery-item-description-front" : "gallery-item-description-back"} style={{height: `${this.state.windowHeight - 170}px`}}>
        {this.state.loading ?
          <div className="small-12 columns text-center">
            <Loading style={{ margin: '50% 0', width: '85px' }}/>
          </div>
        : full_item && currentTab == 'description' ?
          <div className="product-details">
            <h3>{full_item.item_name}</h3>
            <p dangerouslySetInnerHTML={{__html: full_item.item_description}} />
            <br />
            {full_item.price_label != '' ?
              <p style={{fontWeight: 'bold'}}>{full_item.price_label}</p>
            : null}
            {full_item.product_warnings && show_product_warnings == 1 ?
              <ProductWarnings warnings={full_item.product_warnings} />
            : null}
            {full_item.item_warnings.map(iw =>
              <ItemWarning key={iw.item_warning_id} locked={true} item_warning={iw} />
            )}
            {this.state.show_price_grid && order.hide_pricing == 0 ?
              <div>
                <h5>Pricing</h5>
                <table className="pricing">
                  <tbody>
                    <tr>
                      <td>Qty</td>
                      {full_item.options.map(o =>
                        o.total_units != '0' && o.hidden != 1 ?
                          <td key={'qty_item_option_'+o.item_id+o.option_item_id}>{o.total_units}</td>
                        : null
                      )}
                    </tr>
                    <tr>
                      <td>Price</td>
                      {full_item.options.map(o =>
                        o.unit_price != '0.00' && o.hidden != 1 ?
                          <td key={'price_item_option_'+o.item_id+o.option_item_id}>{order.currency_symbol}{formatMoney(o.unit_price)}</td>
                        : null
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            : null}
            {fixed_costs.length != 0 && full_item.share_fixed_charges == 1 && order.hide_pricing == 0 ?
              <div>
                <h5>Fixed Charges</h5>
                <table>
                  <tbody>
                  {fixed_costs.map(i =>
                    <tr>
                      <td>{i.item_cost_title}</td>
                      <td>{order.currency_symbol}{formatMoney(i.unit_price)}</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            : null}
            {/* full_item.product_colors ?
              <div>
                <h5>Colors</h5>
                <p>{this.handleTitleCase(full_item.product_colors)}</p>
              </div>
            : null}
            {full_item.product_sizes ?
              <div>
                <h5>Sizes</h5>
                <p>{full_item.product_sizes}</p>
              </div>
            : null */}
              {full_item.item_colors && full_item.item_colors.length > 0 && !full_item.isPS ?
                <div style={{ marginBottom: '20px' }}>
                  <h5>Colors</h5>
                  <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                    {full_item.item_colors.map((c, idx) =>
                      <Fragment key={c.item_color_id}>
                        <span style={{ color: colors.neutrals['100'] }}>{toTitleCase(c.color_name)}</span>
                        {idx < full_item.item_colors.length - 1 && <span style={{ marginRight: 8, marginLeft: 8, color: colors.neutrals['70'] }}>·</span>}
                      </Fragment>
                    )}
                  </div>
                </div>
                : null}
              {full_item.item_sizes && full_item.item_sizes.length > 0 && !full_item.isPS ?
                <div>
                  <h5>Sizes</h5>
                  <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                    {full_item.item_sizes.map((s, idx) =>
                      <Fragment key={s.item_size_id}>
                        <span style={{ color: colors.neutrals['100'] }}>{s.size_name.toUpperCase()}</span>
                        {idx < full_item.item_sizes.length - 1 && <span style={{ marginRight: 8, marginLeft: 8, color: colors.neutrals['70'] }}>·</span>}
                      </Fragment>
                    )}
                  </div>
                </div>
                : null}
              {full_item.item_skus && full_item.item_skus.length > 0 && full_item.isPS ?
                <div>
                  <h5>Options</h5>
                  {axes.map(
                    axis_name => <div key={axis_name}>
                      <b>{toTitleCase(axis_name)}</b>
                      <div style={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
                        {sku_options[axis_name].map(
                          (sku_option, idx) =>
                            <Fragment key={idx}>
                              <span style={{ color: colors.neutrals['100'] }}>{sku_option}</span>
                              {idx < sku_options[axis_name].length - 1 && <span style={{ marginRight: 8, marginLeft: 8, color: colors.neutrals['70'] }}>·</span>}
                            </Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                : null}
              {this.renderUpCharges()}
            </div>
            : full_item && currentTab == 'comment' ?
              <div className="product-details">
                {this.state.windowWidth < 640 ?
                  <div>
                    <div className="small-7 columns">
                      <h5 style={{ display: 'inline-block' }}>Comment On This Product</h5>
                    </div>
                    <div className="small-5 columns">
                      <button className="button" disabled={post_disabled} style={{ display: 'inline-block', float: 'right', width: 'auto' }} onClick={e => { e.preventDefault(); this.handleAddComment(full_item.item_id); }}>Comment</button>
                    </div>
                    <div className="columns">
                      <input type="text" ref="name" value={this.state.name} placeholder="Your name" onChange={e => handleChangeName(this.refs.name.value)} />
                      <TextArea value={this.state.comment} ref="comment" disabled={comment_disabled} placeholder="Comment on this product..." onChange={e => { this.handleChangeComment(e.target.value); }}></TextArea>
                    </div>
                  </div>
                  : null}
                <div className="medium-12 columns">
                  {this.state.windowWidth >= 640 ?
                    <button className="button btn" onClick={e => { e.preventDefault(); handleSwitchTab('description'); }} style={{ backgroundColor: '#929798', width: '40%', height: '57px' }}>&lt; Product Description</button>
                    : null}
                  <ul className="presentation-comments" style={{ listStyle: 'none', marginLeft: '0px' }}>
                    {Object.values(full_item.comments).map(c =>
                      <li key={c.presentation_comment_id}>
                        <div className="row" style={c.rep_comment == 1 ? rep_comment_style : null}>
                          <div className="small-8 columns" style={{ padding: '0px' }}>
                            <div className="commenter">{c.commenter_name}:</div>
                            <span>{c.comment}</span>
                          </div>
                          <div className="small-4 columns" style={{ padding: '0px' }}>
                            <p style={{ float: 'right' }}>{parseMysqlDate(c.date_created)}</p>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                {this.state.windowWidth >= 640 ?
                  <div className="gallery-comment-fields" style={portal ? { bottom: '100px' } : null}>
                    <input type="text" ref="name" value={this.state.name} placeholder="Your name" onChange={e => handleChangeName(this.refs.name.value)} />
                    <TextArea value={this.state.comment} ref="comment" disabled={comment_disabled} placeholder="Comment on this product..." onChange={e => { this.handleChangeComment(e.target.value); }}></TextArea>
                    <button className="button" style={{ height: '57px' }} disabled={post_disabled} onClick={e => { e.preventDefault(); this.handleAddComment(full_item.item_id); }}>Add Comment</button>
                  </div>
                  : null}
              </div>
              : null}
        {order && order.view_type === 'SMART' && this.state.windowWidth >= 640 && currentTab != 'comment' && order.disable_smart == 0 && !sales_order_checked_out ?
          <button className="button btn alt" id="order" onClick={e => { e.preventDefault(); onCreateAddToQuotePopup(full_item.item_id, 'OPTION'); }} style={order_button_style}>
            {this.state.cart[full_item.item_id] ?
              <div><p style={{ display: 'inline' }}>Added</p><div id="order-items">{this.state.cart[full_item.item_id].total_quantity}</div></div>
              :
                <div>Add to Order</div>
              }
            </button>
          : null}
          {currentTab != 'comment' && this.state.windowWidth >= 640 ?
            <button
                className={order && order.view_type === 'SMART' && order.disable_smart == 0 && !sales_order_checked_out ?
                  'button btn comment-button-smart'
                : 'button btn comment-button'}
                onClick={e => {e.preventDefault(); handleSwitchTab('comment');}}
                style={order && order.view_type === 'SMART' && order.disable_smart == 0 && !sales_order_checked_out && portal ? {marginBottom: '100px'} :
                  order.view_type === 'GALLERY' && portal ? {bottom: '120px'} : null}>
              <div style={{display: 'inline-block'}}>Comment <span className="comment-length">{Object.values(full_item.comments).length}</span></div>
            </button>
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const full_item = (state.entities && state.entities.items && ownProps.currentItemType === 'OPTION')
    ? getFullOptionItem(state, { item_id: ownProps.currentItemId }) : state.items && ownProps.currentItemType === 'OPTION' ? Object.values(state.items).filter(i => i.item_id == ownProps.currentItemId)[0]
    : null;
  const order = (state.entities && state.entities.orders && ownProps.currentItemType === 'AVATAR') ? state.entities.orders[ownProps.currentItemId] : state.items ? state : null;
  const company_name = state.identity ? state.identity.company_name : state.sales_rep ? state.sales_rep.network_name : null;
  const username = state.identity ? `${state.identity.user_first_name} ${state.identity.user_last_name}` : state.sales_rep ? state.sales_rep.contact_full_name : null;
  const company_address = (state.entities && state.entities.addresses) ? Object.values(state.entities.addresses).filter(a => a.address_id == state.dropdowns.addresses[state.identity.company_id][0])[0] : state.address ? state.address : null;
  const cart = state.temp;
  const sales_order_checked_out = state.sales_order_checked_out ? state.sales_order_checked_out : null;
  const show_product_warnings = state.show_product_warnings ? state.show_product_warnings : null;

  return {
    full_item,
    items: state.items,
    order,
    company_name,
    username,
    company_address,
    cart,
    sales_order_checked_out,
    show_product_warnings
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddComment: (item_id, commenter_name, comment) => {
      dispatch(createAddComment(item_id, null, commenter_name, null, comment));
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    },
    onCreateDeleteTemp: (key) => {
      dispatch(createDeleteTemp(key));
    },
    onFetchProductItems: (selected, order_id) => {
      dispatch(fetchProductItems(selected, order_id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryText);
