import React, { Component } from 'react';
import URI from 'urijs';
import { connect } from 'react-redux';

import { getItemImagesByItemId } from '../selectors';

import Gallery from './Gallery';
import GallerySnippet from './GallerySnippet';

import { window } from '../global';
import { getImageSrc, getAbsoluteUrl } from '../utils';
import { createAddToQuotePopup } from '../actions/popup';
import WebsocketContext from '../context/websocket-context';

import GalleryChatPortal from './chat/GalleryChatPortal';
import GalleryChat from './chat/GalleryChat';

class GalleryPreview extends Component {
  static contextType = WebsocketContext;

  constructor(props) {
    super(props);

    this.wrapText = this.wrapText.bind(this);
    this.handleInitial = this.handleInitial.bind(this);
    this.handleGeometry = this.handleGeometry.bind(this);
    this._renderItem = this._renderItem.bind(this);
    this._handleImageError = this._handleImageError.bind(this);
    this.handleClickSnippet = this.handleClickSnippet.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleToggleExpandIntro = this.handleToggleExpandIntro.bind(this);
    this.grabLightboxStatus = this.grabLightboxStatus.bind(this);
    this.handleQuote = this.handleQuote.bind(this);
    this.handleCancelQuote = this.handleCancelQuote.bind(this);
    this.handleFilter = this.handleFilter.bind(this);

    this.childGallery = React.createRef();

    this.state = {
      gallery_items: props.items.map(i =>
      ({
        src: getImageSrc(i.images[0], 'large'),
        thumbnail: getImageSrc(i.images[0], 'large'),
        thumbnailWidth: i.medium_width ? i.medium_width : 500,
        thumbnailHeight: i.medium_height ? i.medium_height : 500,
        caption: i.item_name,
        description: i.item_description,
        item_id: i.item_id,
        parent_type: i.parent_type,
        item_name: i.item_name,
        original: getImageSrc(i.images[0], 'large'),
        renderItem: this._renderItem,
        images: i.images,
        files: i.images,
        share_all_images: i.share_all_images,
        default_file: i.images[0],
        price_label: i.price_label,
      })
      ),
      location_url: window.location.href,
      windowWidth: window.innerWidth,
      full_intro: false,
      lightboxIsOpen: false,
      windowHeight: window.innerHeight,
      cart: props.cart,
      in_quote: false,
      in_filter: false,
      filter: '',
      chats: [],
      users: [],
    };
  }

  componentDidMount() {
    this.handleInitial();
    window.addEventListener('resize', this.handleResize);

    // Important: set up the websocket context to use this as the root component
    // to send/receive remote commands
    this.context.setRootComponent(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ cart: nextProps.cart });
  }

  handleQuote() {
    let preview = this;
    let quote_items = this.state.gallery_items.slice();

    Object.keys(this.state.cart).forEach(function (item_id) {
      let quantity = preview.state.cart[item_id];

      for (let index in quote_items) {
        if (quote_items[index].item_id === item_id) {
          quote_items[index].quantity = quantity;
        }
      }
    });

    this.setState({ gallery_items: quote_items.filter(qi => qi.quantity), in_quote: true }, function () {
      this.handleGeometry(this.state.gallery_items);
    });
  }

  handleCancelQuote() {
    let before_gallery_items = this.props.items.map(i =>
    ({
      src: getImageSrc(i.images[0], 'large'),
      thumbnail: getImageSrc(i.images[0], 'large'),
      thumbnailWidth: i.medium_width ? i.medium_width : 500,
      thumbnailHeight: i.medium_height ? i.medium_height : 500,
      caption: i.item_name,
      description: i.item_description,
      item_id: i.item_id,
      parent_type: i.parent_type,
      item_name: i.item_name,
      original: getImageSrc(i.images[0], 'large'),
      renderItem: this._renderItem,
      files: i.images,
      images: i.images,
      share_all_images: i.share_all_images,
      default_file: i.images[0],
      price_label: i.price_label,
    })
    );

    this.setState({ gallery_items: before_gallery_items, in_quote: false }, function () {
      this.handleGeometry(this.state.gallery_items);
    });
  }

  handleFilter(filter, triggerRemote = true) {
    if (filter === 'null') filter = null; // Normalize string null to null
    if (triggerRemote) {
      this.context.sendMessage('broadcast:handleFilter:' + filter);
    }

    let before_gallery_items = this.props.items.map(i =>
    ({
      src: getImageSrc(i.images[0], 'large'),
      thumbnail: getImageSrc(i.images[0], 'large'),
      thumbnailWidth: i.medium_width ? i.medium_width : 500,
      thumbnailHeight: i.medium_height ? i.medium_height : 500,
      caption: i.item_name,
      description: i.item_description,
      item_id: i.item_id,
      parent_type: i.parent_type,
      item_name: i.item_name,
      original: getImageSrc(i.images[0], 'large'),
      renderItem: this._renderItem,
      files: i.images,
      images: i.images,
      share_all_images: i.share_all_images,
      default_file: i.images[0],
      price_label: i.price_label,
    })
    );

    if (!filter) {
      /* let item_refs = Object.assign({}, this.refs);
      for (let ref in item_refs) {
        let tCtx = this.refs[ref].getContext('2d');
        let src = tCtx.canvas.toDataURL();

        for (let index in before_gallery_items) {
          if (before_gallery_items[index].item_id == ref.substr(5)) {
            before_gallery_items[index].original = src;
            before_gallery_items[index].thumbnail = src;
            before_gallery_items[index].thumbnailWidth = 500;
            before_gallery_items[index].thumbnailHeight = 500;
            before_gallery_items[index].parent_type = 'SEPARATOR';
          }
        }
      } */

      this.setState({ gallery_items: before_gallery_items.filter(i => i.parent_type !== 'SEPARATOR'), in_filter: false, filter: '' }, function () {
        this.handleGeometry(this.state.gallery_items);
      });
    } else {
      let sep_index = [];
      for (let i = 0; i < before_gallery_items.length; i++) {
        if (before_gallery_items[i].parent_type == 'SEPARATOR') {
          sep_index.push(i);
        }
      }

      for (let index in before_gallery_items) {
        for (let i = 0; i < sep_index.length; i++) {
          if (index >= sep_index[i]) {
            before_gallery_items[index].junk = before_gallery_items[sep_index[i]].item_id;
          }
        }
      }

      this.setState({ gallery_items: before_gallery_items.filter(i => i.junk === filter && i.parent_type !== 'SEPARATOR'), in_filter: true, filter: filter }, function () {
        this.handleGeometry(this.state.gallery_items);
      });
    }
  }

  handleResize(e) {
    if (window.innerWidth != this.state.windowWidth) {
      this.setState({ windowWidth: window.innerWidth });
      this.handleGeometry(this.state.gallery_items);
    }

    this.setState({ windowHeight: window.innerHeight });
  }

  handleToggleExpandIntro() {
    this.setState({ full_intro: !this.state.full_intro }, function () {
      if (this.state.full_intro == false) {
        window.scrollTo(0, 0);
      }
    });
  }

  grabLightboxStatus(lightboxIsOpen) {
    this.setState({ lightboxIsOpen: lightboxIsOpen });
  }

  wrapText(context, text, x, y, maxWidth, lineHeight) {
    var words = text.split(' ');
    var line = '';

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      }
      else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  handleInitial() {
    let new_gallery_items = this.state.gallery_items.slice();
    /* let item_refs = Object.assign({}, this.refs);
    for (let ref in item_refs) {
      let tCtx = this.refs[ref].getContext('2d');
      let title_name = this.props.items.filter(i => i.item_id == ref.substr(5))[0].item_name;
      tCtx.fillStyle = 'black';

      if(title_name.length < 12) {
        tCtx.font="80px Arial";
        tCtx.textAlign = 'center';
        tCtx.fillText(title_name, 250, 250);
      }else if(title_name.length >= 12 && title_name.length < 30) {
        tCtx.font="60px Arial";
        this.wrapText(tCtx, title_name, 50, 200, 400, 85);
      }else if(title_name.length >= 30 && title_name.length <= 45) {
        tCtx.font="50px Arial";
        this.wrapText(tCtx, title_name, 50, 200, 400, 50);
      }else if(title_name.length > 45 && title_name.length < 100) {
        tCtx.font="30px Arial";
        this.wrapText(tCtx, title_name, 50, 200, 400, 50);
      }else if(title_name.length >= 100){
        tCtx.font="30px Arial";
        let posY = 200;
        let leftover_rows = (title_name.length - 100) / 25;
        let final_posY = 200 - (25 * leftover_rows);

        if(final_posY > 50) {
          this.wrapText(tCtx, title_name, 50, final_posY, 400, 50);
        }else{
          let placeholder = 'Please use a shorter title for a better presentation experience to the client.';
          this.wrapText(tCtx, placeholder, 50, 200, 400, 50);
        }
      }

      let src = tCtx.canvas.toDataURL();

      for (let index in this.state.gallery_items) {
        if (this.state.gallery_items[index].item_id == ref.substr(5)) {
          new_gallery_items[index].original = src;
          new_gallery_items[index].thumbnail = src;
          new_gallery_items[index].thumbnailWidth = 500;
          new_gallery_items[index].thumbnailHeight = 500;
          new_gallery_items[index].parent_type = 'SEPARATOR';
        }
      }
    } */

    let sep_index = [];
    for (let i = 0; i < this.state.gallery_items.length; i++) {
      if (this.state.gallery_items[i].parent_type == 'SEPARATOR') {
        sep_index.push(i);
      }
    }

    for (let index in this.state.gallery_items) {
      for (let i = 0; i < sep_index.length; i++) {
        if (index >= sep_index[i]) {
          new_gallery_items[index].junk = new_gallery_items[sep_index[i]].item_id;
        }
      }
    }

    this.handleGeometry(new_gallery_items.filter(i => i.parent_type != 'SEPARATOR'));
  }

  handleGeometry(all_gallery_items) {
    let boxes = all_gallery_items.map(g => ({
      width: g.thumbnailWidth,
      height: g.thumbnailHeight
    }));

    let setWidth = this.state.windowWidth;

    var layoutGeometry = require('justified-layout')(boxes, {
      containerWidth: setWidth,
      targetRowHeight: 320,
    });

    let final_gallery_items = all_gallery_items.slice();
    for (let index in layoutGeometry['boxes']) {
      final_gallery_items[index].width = layoutGeometry['boxes'][index].width;
      final_gallery_items[index].top = layoutGeometry['boxes'][index].top;
      final_gallery_items[index].left = layoutGeometry['boxes'][index].left;
      final_gallery_items[index].height = layoutGeometry['boxes'][index].height;
    }

    this.setState({ gallery_items: final_gallery_items });
  }

  handleClickSnippet(file, item_id) {
    const new_gallery_items = this.state.gallery_items.slice();
    for (let index in this.state.gallery_items) {
      if (this.state.gallery_items[index].item_id == item_id) {
        new_gallery_items[index].original = getImageSrc(file, 'large');;
      }
    }
    const state = Object.assign({}, this.state, { gallery_items: new_gallery_items });
    this.setState(state);
  }

  _handleImageError(event) {
    if (this.props.defaultImage &&
      event.target.src.indexOf(this.props.defaultImage) === -1) {
      event.target.src = this.props.defaultImage;
    }
  }

  _renderItem(item) {
    const onImageError = this.props.onImageError || this._handleImageError;

    let item_key;
    for (let index in this.state.gallery_items) {
      if (this.state.gallery_items[index].item_id == item.item_id) {
        item_key = index;
      } else if (item.parent_type == 'INTRO') {
        item_key = 2;
      } else if (item.parent_type == 'CONTACT') {
        item_key = this.state.gallery_items.length - 1;
      }
    }

    let url = URI();
    let portal = url.search(true).portal;

    let offset = this.state.windowWidth < 640 ? 380 : 440;
    offset = portal ? offset + 100 : offset;

    return (
      <div className='image-gallery-image'>
        <GallerySnippet items={this.state.gallery_items} index={item_key} handleClickSnippet={this.handleClickSnippet} />
        <img
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          onLoad={this.props.onImageLoad}
          onError={onImageError.bind(this)}
          //width={item.thumbnailWidth}
          //height={item.thumbnailWidth}
          style={{ height: this.state.windowHeight - offset }}
        />
        {/*
          item.caption &&
            <span className='image-gallery-description'>
              {item.caption}
            </span>
        */}
      </div>
    );
  }

  render() {
    const {
      order,
      items,
      popups,
      company_avatar_url,
      company_name,
      username,
      user_email,
      user_phones,
      company_address,
      cart,
      position,
      onCreateAddToQuotePopup,
      sales_order_checked_out,
      order_template,
      resku_form,
      client_name
    } = this.props;

    return (
      <div className="intro">
        <div className="gallery-relative">
          <Gallery
            ref={this.childGallery}
            images={this.state.gallery_items}
            enableImageSelection={true}
            margin={10} rowHeight={400}
            showThumbnails={true}
            items={items}
            handleClickSnippet={this.handleClickSnippet}
            order={order}
            company_avatar_url={company_avatar_url}
            company_name={company_name}
            username={username}
            user_phones={user_phones}
            company_address={company_address}
            location_url={this.state.location_url}
            grabLightboxStatus={this.grabLightboxStatus}
            windowHeight={this.state.windowHeight}
            handleQuote={this.handleQuote}
            handleCancelQuote={this.handleCancelQuote}
            in_quote={this.state.in_quote}
            cart={this.state.cart}
            position={position}
            user_email={user_email}
            handleFilter={this.handleFilter}
            onCreateAddToQuotePopup={onCreateAddToQuotePopup}
            windowWidth={this.state.windowWidth}
            filter={this.state.filter}
            sales_order_checked_out={sales_order_checked_out}
            order_template={order_template}
            resku_form={resku_form}
            client_name={client_name}
            sendMessage={this.sendMessage}
          />
          {items.filter(i => i.parent_type === 'SEPARATOR').map(i =>
            <canvas key={i.item_id} ref={`text_${i.item_id}`} width={500} height={500} style={{ display: 'none' }}></canvas>
          )}
        </div>
        <GalleryChatPortal>
          <GalleryChat
            chats={this.state.chats}
            users={this.state.users}
            handleAddChat={this.handleAddChat}
            handleCall={this.callUser}
            handleAnswerCall={this.handleAnswerCall}
            handleEndCall={this.handleEndCall}
          />
        </GalleryChatPortal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = ownProps.order;
  const items = (state.entities && state.entities.items) ?
    Object.values(state.entities.items).filter(i => i.order_id === order.order_id && i.hidden == 0 && (i.parent_type === 'OPTION' || i.parent_type === 'SEPARATOR')).map(i => ({ ...i, images: getItemImagesByItemId(state, i) })) :
    ownProps.items ?
      Object.values(ownProps.items).filter(i => i.order_id === order.order_id && i.hidden == 0 && (i.parent_type === 'OPTION' || i.parent_type === 'SEPARATOR')).map(i => ({ ...i, images: (i.item_images || []).map(ii => (i.files || []).filter(f => f.file_id === ii.file_id)[0]) })) : [];
  const company_avatar_url = state.identity ? getAbsoluteUrl(state.identity.company_avatar.medium) : state.company_avatar && state.company_avatar.medium ? getAbsoluteUrl(state.company_avatar.medium) : '/images/favicons/favicon-96x96.png';
  const company_name = state.identity ? state.identity.company_name : state.sales_rep ? state.sales_rep.network_name : null;
  const order_template = state.order_template ? state.order_template : null;
  const resku_form = state.resku_form;
  const rep = order_template && resku_form && order_template.footer_user_type === 'CLIENT-REP' ? state.client_rep : state.sales_rep;

  const chat_username = state.identity ? `${state.identity.user_first_name} ${state.identity.user_last_name}` : null;
  const username = chat_username ? chat_username : rep ? rep.contact_full_name : null;

  const user_email = state.identity ? state.identity.user_email : rep ? rep.contact_email : null;
  const user_phones = state.identity ? state.identity.user_phones.filter(p => p.phone_number != '') : rep ? rep.phones.filter(p => p.phone_number != '') : null;
  const company_address = (state.entities && state.entities.addresses) ? Object.values(state.entities.addresses).filter(a => a.address_id == state.dropdowns.addresses[state.identity.company_id][0])[0] : state.address ? state.address : null;
  const cart = state.temp;
  const position = rep ? rep.contact_position : null;
  const sales_order_checked_out = state.sales_order_checked_out ? state.sales_order_checked_out : null;
  const client_name = state.client.client_name;

  return {
    order,
    items,
    company_avatar_url,
    company_name,
    chat_username,
    username,
    user_email,
    user_phones,
    company_address,
    cart,
    position,
    sales_order_checked_out,
    order_template,
    resku_form,
    client_name
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateAddToQuotePopup: (item_id, item_type) => {
      dispatch(createAddToQuotePopup(item_id, item_type));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryPreview);
