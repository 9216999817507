import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CheckButton from './CheckButton';
import { createAddToQuotePopup } from '../actions/popup';
import { getFullOptionItem } from '../selectors';
import { formatMoney } from '../utils';
import { window } from '../global';

class Image extends Component {
	constructor (props) {
		super(props);

		this.state = {
			hover: false
		};
	}

	tileViewportStyle () {
		if (this.props.item.isSelected)
			return {
				width: this.props.item.vwidth -32,
				height: this.props.height -32,
				margin: 16,
				overflow: "hidden"
			};
		return {
			//width: ""+this.props.item.vwidth+"px",
			//height: this.props.height,
			overflow: "hidden"
		};
	}

	thumbnailStyle () {
		/* if (this.props.item.isSelected){
			var ratio = (this.props.item.scaletwidth / this.props.height);
			var height = 0;
			var width = 0;
			var viewportHeight = (this.props.height - 32);
			var viewportWidth = (this.props.item.vwidth -32);

			if(this.props.item.scaletwidth > this.props.height){
				width = this.props.item.scaletwidth -32;
				height = Math.floor(width / ratio);
			}
			else {
				height = this.props.height -32;
				width = Math.floor(height * ratio);
			}

			var marginTop = -Math.abs(Math.floor((viewportHeight - height) / 2));
			var marginLeft = -Math.abs(Math.floor((viewportWidth - width) / 2));
			return {
				cursor: 'pointer',
				width: width,
				height: height,
				marginLeft: marginLeft,
				marginTop: marginTop
			};
		} */

		if(this.state.hover && !this.props.item.isSelected && this.props.isSelectable && this.props.item.parent_type == 'OPTION' && window.innerWidth > 640) {
			return {
				cursor: 'pointer',
				width: '100%',
				opacity: 0.2,
				WebkitTransition: 'all 1s',
				transition: 'all 1s',
				transform: 'scale(1.05, 1.05)',
			};
		}

		return {
			cursor: 'pointer',
			width: '100%'
			//width: this.props.item.scaletwidth,
			//height: this.props.height,
			//marginLeft: this.props.item.marginLeft,
			//marginTop: 0
		};
	}

	renderCheckButton () {
		return (
				<CheckButton key="Select"
			index={this.props.index}
			color={"rgba(255, 255, 255, 0.7)"}
			selectedColor={"#4285f4"}
			hoverColor={"rgba(255, 255, 255, 1)"}
			isSelected={this.props.item.isSelected}
			isSelectable={this.props.isSelectable}
			onClick={this.props.isSelectable ?
					 this.props.onImageSelected : null}
			parentHover={this.state.hover}/>
		);
	}

	render () {
		const {full_item, quantity, cart, display_prices, view_type, currency_symbol, hide_pricing, sales_order_checked_out, onCreateAddToQuotePopup} = this.props;
		const overlayStyle = this.state.hover && !this.props.item.isSelected && this.props.isSelectable && this.props.item.parent_type == 'OPTION' && window.innerWidth > 640 ?
			{
				pointerEvents: "none",
				opacity: 1,
				position: "absolute",
				height: "100%",
				width: "100%",
				background: '#fff',
				transition: 'opacity 1s ease-in-out'
			} :
			{
				pointerEvents: "none",
				opacity: 0.5,
				position: "absolute",
				height: "100%",
				width: "100%",
				background: 'none'
			};

		return (
				<div className="tile"
			key={"tile-"+this.props.index}
			onMouseEnter={(e) => this.setState({hover: true})}
			onMouseLeave={(e) => this.setState({hover: false})}
			style={{
				//margin: ""+this.props.margin+"px",
				WebkitUserSelect: "none",
				//position: "relative",
				//float: "left",
				background: "#FFF",
				//padding: "0px",
				//marginBottom: "50px",
				width: this.props.width,
				top: this.props.top,
				left: this.props.left,
				height: this.props.height,
				position: "absolute",
				outline:  "10px solid #f2f2f2",
				overflow: "hidden"
				}}>

				{/* <div className="tile-icon-bar"
			key={"tile-icon-bar-"+this.props.index}
			style={{
				pointerEvents: "none",
				opacity: 1,
				position: "absolute",
				height: "36px",
				width: "100%"}}>
				{this.renderCheckButton()}
				</div> */}

				<div className="tile-overlay"
			key={"tile-overlay-"+this.props.index}
			style={overlayStyle}>
				{this.state.hover && !this.props.item.isSelected && this.props.isSelectable && this.props.item.parent_type == 'OPTION' && window.innerWidth > 640 ?
					<div style={{padding: '10px', textAlign: 'center', paddingTop: '100px'}}>
						<br />
						<div style={{fontWeight: 'bold', fontSize: '18px'}}>{this.props.item.item_name}</div>
						<br />
						<div style={{fontWeight: 'bold', fontSize: '15px'}}>{this.props.item.price_label}</div>
						<br />
						{+full_item.share_pricing === 1 && +hide_pricing === 0 ?
							<div style={{fontWeight: 'bold', color: '#5CA3B6'}}>{display_prices}</div>
						: null}
						{/* <div>{this.props.item.description.length > 100 ? this.props.item.description.substring(0, 100) + '...' : this.props.item.description}</div> */}
					</div>
				: null}
				</div>

				<div className="tile-viewport"
			style={
				this.tileViewportStyle()
			}
			key={"tile-viewport-"+this.props.index}
			onClick={this.props.onClick ?
					 (e) => this.props.onClick(this.props.index, e) : null}>

				{full_item && Object.values(full_item.comments).length == 1 && Object.values(full_item.comments)[0].rep_comment == 0 ?
					<i className="fi-comment gallery-client-comment-icon"></i>
				: full_item && Object.values(full_item.comments).length == 1 && Object.values(full_item.comments)[0].rep_comment == 1 ?
					<i className="fi-comment gallery-comment-icon"></i>
				: full_item && Object.values(full_item.comments).length > 1 && Object.values(full_item.comments)[Object.values(full_item.comments).length - 1].rep_comment == 0 ?
					<i className="fi-comments gallery-client-comment-icon"></i>
				: full_item && Object.values(full_item.comments).length > 1 && Object.values(full_item.comments)[Object.values(full_item.comments).length - 1].rep_comment == 1 ?
					<i className="fi-comments gallery-comment-icon"></i>
				: null}

				{['SMART', 'SHOP_DESIGN'].includes(view_type) && quantity ?
					<div className="in-cart">
						<div className="in-cart-quantity">
							{quantity}
						</div>
					</div>
				: null}

				{this.state.hover && !this.props.item.isSelected && this.props.isSelectable && this.props.item.parent_type == 'OPTION' && window.innerWidth > 640  && ['SMART', 'SHOP_DESIGN'].includes(view_type) && !quantity && this.props.order.disable_smart == 0 && !sales_order_checked_out ?
					<button id="order" className="in-cart in-cart-quantity" style={{width: '50%', fontSize: '15px', zIndex: 10, marginTop: 0}} onClick={e => onCreateAddToQuotePopup(this.props.item.item_id, 'OPTION')}>
						Add to Order
					</button>
				: null}

				<img
			key={"img-"+this.props.index}
			src={this.props.item.thumbnail}
			alt={this.props.item.item_name}
			style={this.thumbnailStyle()} />
				</div>
				</div>
		);
	}
}

Image.propTypes = {item: PropTypes.object,
				   index: PropTypes.number,
				   margin: PropTypes.number,
				   height: PropTypes.number,
				   isSelectable: PropTypes.bool,
				   onClick: PropTypes.func,
				   onImageSelected: PropTypes.func,
				   width: PropTypes.number,
				   top: PropTypes.number,
				   left: PropTypes.number,
				   order: PropTypes.object,
				   onCreateAddToQuotePopup: PropTypes.func,};

Image.defaultProps = {isSelectable: true,
					  hover: false};

const mapStateToProps = (state, ownProps) => {
	const full_item = (state.entities && state.entities.items && ownProps.item.parent_type === 'OPTION') ? getFullOptionItem(state, { item_id: ownProps.item.item_id }) : state.items && ownProps.item.parent_type === 'OPTION' ? Object.values(state.items).filter(i => i.item_id == ownProps.item.item_id)[0] : null;
	const filtered_options = full_item.options.filter(o => o.unit_price != '0.00' && o.hidden != 1);
	const highest_price_quantity = filtered_options.length != 0 ? filtered_options[0].unit_price : full_item.max_price ? full_item.max_price : null;
	const lowest_price_quantity = filtered_options.length != 0 ? filtered_options[filtered_options.length - 1].unit_price : full_item.min_price ? full_item.min_price : null;
	const currency_symbol = state.currency_symbol;
	const display_prices = lowest_price_quantity && highest_price_quantity && lowest_price_quantity != highest_price_quantity
		? `${currency_symbol}${formatMoney(lowest_price_quantity)} - ${currency_symbol}${formatMoney(highest_price_quantity)}`
			: lowest_price_quantity && highest_price_quantity && lowest_price_quantity == highest_price_quantity
				? `${currency_symbol}${formatMoney(lowest_price_quantity)}`
					: '';
	const cart = state.temp;
	const sales_order_checked_out = state.sales_order_checked_out ? state.sales_order_checked_out : null;
	const hide_pricing = state.hide_pricing ? parseInt(state.hide_pricing) : null;

	return {
		full_item,
		cart,
		display_prices,
		view_type: state.view_type,
		currency_symbol,
		sales_order_checked_out,
		hide_pricing
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateAddToQuotePopup: (item_id, item_type) => {
      dispatch(createAddToQuotePopup(item_id, item_type));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
