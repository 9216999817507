import { filter } from 'lodash';
import React, { Component } from 'react';
import URI from 'urijs';
import PropTypes from 'prop-types';

import Image from './Image';
import GalleryText from './GalleryText';
import GalleryIntro from './GalleryIntro';
import ImageGallery from './ImageGallery';
import { window } from '../global';
import WebsocketContext from '../context/websocket-context';

class Gallery extends Component {
    static contextType = WebsocketContext;
    constructor (props) {

        super(props);
        this.state = {
            images: this.props.images,
            thumbnails: [],
            lightboxIsOpen: this.props.isOpen,
            currentImage: this.props.currentImage,
            containerWidth: 0,
            currentItemId: '',
            currentItemType: '',
            name: '',
            isImage: true,
              showIndex: false,
              slideOnThumbnailHover: false,
              showBullets: true,
              infinite: true,
              showThumbnails: true,
              showFullscreenButton: true,
              showGalleryFullscreenButton: true,
              showPlayButton: true,
              showGalleryPlayButton: true,
              showNav: true,
              slideInterval: 2000,
              showVideo: {},
            currentTab: props.windowWidth > 640 ? 'description' : '',
            currentItemComments: [],
            cart: this.props.cart,
            in_quote: this.props.in_quote
        };

        this.handleResize = this.handleResize.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        //this.gotoNext = this.gotoNext.bind(this);
        //this.gotoPrevious = this.gotoPrevious.bind(this);
        //this.gotoImage = this.gotoImage.bind(this);
        //this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.onImageSelected = this.onImageSelected.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeMobileView = this.handleChangeMobileView.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSwitchTab = this.handleSwitchTab.bind(this);
        this.grabComments = this.grabComments.bind(this);
    }

    componentDidMount () {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keydown', this.handleKeyDown);

        /* if(!this.props.order.isLoggedIn) {
            let client_name = window.localStorage.getItem('commonsku_client_name');
            if(client_name) {
                this.setState({ name: client_name });
            }
        } */
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    UNSAFE_componentWillReceiveProps (np) {
        this.setState({
            images: np.images,
            thumbnails: this._gallery ? this.renderThumbs(this._gallery.clientWidth, np.images) : this.props.windowWidth > 640 ? this.renderThumbs(this.props.windowWidth * 0.6, np.images) : this.renderThumbs(window.innerWidth, np.images),
            cart: np.cart,
            in_quote: np.in_quote
        });
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if(nextState.lightboxIsOpen != this.state.lightboxIsOpen) {
            this.props.grabLightboxStatus(nextState.lightboxIsOpen);
        }

        if(nextProps.windowWidth > 640 && this.state.currentTab == 'gallery') {
            this.setState({ currentTab: 'description' });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.slideInterval !== prevState.slideInterval) {
          // refresh setInterval
          this._imageGallery.pause();
          this._imageGallery.play();
        }

        if (!this._gallery) return;
        if (this._gallery.clientWidth
            !== this.state.containerWidth){
            this.handleResize();
        }
    }

    handleSlideToIndex(index, event, triggerRemote = true) {
        this._imageGallery.slideToIndex(index, event, triggerRemote);
    }

    handleResize () {
        if (!this._gallery) return;

        this.setState({
            containerWidth: Math.floor(this._gallery.clientWidth),
            thumbnails: this.renderThumbs(this._gallery.clientWidth),
            windowWidth: window.innerWidth
        });
    }

    handleKeyDown (e) {
        if(e.keyCode == 27 && this.state.lightboxIsOpen == true) {
            this.setState({ lightboxIsOpen: false});
            return this.closeLightbox;
        }
    }

    handleSwitchTab(tab) {
      this.setState({ currentTab: tab });
      if(this.props.windowWidth < 640 && this.state.isImage) {
        this.handleChangeMobileView();
      }
    }

    grabComments(comments) {
      this.setState({ currentItemComments: comments });
    }

    openLightbox (index, event, triggerRemote = true) {
        try {
            event.preventDefault();
        } catch (err) {
            // no event
        }
        if (triggerRemote) {
            this.context.sendMessage('broadcast:childGallery:openLightbox:' + index + ':event');
        }

        if(window.innerWidth < 640) {
            if(this.props.images[index].parent_type !== 'OPTION') {
                this.setState({
                    currentImage: index,
                    lightboxIsOpen: true,
                    currentItemId: this.props.images[index].item_id,
                    currentItemType: this.props.images[index].parent_type,
                    isImage: true,
                    currentTab: 'gallery'
                });
            }else if(this.props.images[index].parent_type === 'OPTION' && this.state.currentTab === '') {
                this.setState({
                    currentImage: index,
                    lightboxIsOpen: true,
                    currentItemId: this.props.images[index].item_id,
                    currentItemType: this.props.images[index].parent_type,
                    currentTab: 'gallery'
                });
            }else{
                this.setState({
                    currentImage: index,
                    lightboxIsOpen: true,
                    currentItemId: this.props.images[index].item_id,
                    currentItemType: this.props.images[index].parent_type
                });
            }
        }else{
            if(this.state.currentTab === 'gallery') {
                this.setState({
                    currentImage: index,
                    lightboxIsOpen: true,
                    currentItemId: this.props.images[index].item_id,
                    currentItemType: this.props.images[index].parent_type,
                    isImage: true,
                    currentTab: 'description'
                });
            }else{
                this.setState({
                    currentImage: index,
                    lightboxIsOpen: true,
                    currentItemId: this.props.images[index].item_id,
                    currentItemType: this.props.images[index].parent_type
                });
            }
        }
    }

    closeLightbox (triggerRemote = true) {
        if (triggerRemote) {
            this.context.sendMessage('broadcast:childGallery:closeLightbox');
        }

        this.setState({
            currentImage: 0,
            lightboxIsOpen: false
        });
    }

    /* gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
            currentItemId: this.props.images[this.state.currentImage - 1].item_id,
            currentItemType: this.props.images[this.state.currentImage - 1].parent_type
        });
    }

    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
            currentItemId: this.props.images[this.state.currentImage + 1].item_id,
            currentItemType: this.props.images[this.state.currentImage + 1].parent_type
        });
    }

    gotoImage (index) {
        this.setState({
            currentImage: index,
            currentItemId: this.props.images[index].item_id,
            currentItemType: this.props.images[index].parent_type
        });
    }

    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1)
            return;
        this.gotoNext();
    } */

    onImageSelected (index, event) {
        event.preventDefault();
        if(this.props.onImageSelected)
            this.props.onImageSelected(index, this.state.images[index]);
    }

    getOnClickThumbnailFunc () {
        if(!this.props.onClickThumbnail && this.props.enableLightbox)
            return this.openLightbox;
        if(this.props.onClickThumbnail)
            return this.props.onClickThumbnail;
        return null;
    }

    calculateCutOff (len, delta, items) {
        var cutoff = [];
        var cutsum = 0;
        for(var i in items) {
            var item = items[i];
            var fractOfLen = item.scaletwidth / len;
            cutoff[i] = Math.floor(fractOfLen * delta);
            cutsum += cutoff[i];
        }

        var stillToCutOff = delta - cutsum;
        while(stillToCutOff > 0) {
            for(i in cutoff) {
                cutoff[i]++;
                stillToCutOff--;
                if (stillToCutOff < 0) break;
            }
        }
        return cutoff;
    }

    buildImageRow (items, containerWidth) {
        var row = [];
        var len = 0;
        var imgMargin = 2 * this.props.margin;
        while(items.length > 0 && len < containerWidth) {
            var item = items.shift();
            row.push(item);
            len += (item.scaletwidth + imgMargin);
        }

        var delta = len - containerWidth;
        if(row.length > 0 && delta > 0) {
            var cutoff = this.calculateCutOff(len, delta, row);
            for(var i in row) {
                var pixelsToRemove = cutoff[i];
                item = row[i];
                item.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2));
                item.vwidth = item.scaletwidth - pixelsToRemove;
            }
        }
        else {
            for(var j in row) {
                item = row[j];
                item.marginLeft = 0;
                item.vwidth = item.scaletwidth;
            }
        }
        return row;
    }

    setThumbScale (item) {
        item.scaletwidth =
            Math.floor(this.props.rowHeight
                       * (item.thumbnailWidth / item.thumbnailHeight));
    }

    renderThumbs (containerWidth, images = this.state.images) {
        if (!images) return [];
        if (containerWidth == 0) return [];

        var items = images.slice();
        for (var t in items) {
            this.setThumbScale(items[t]);
        }

        var thumbs = [];
        var rows = [];
        while(items.length > 0) {
            rows.push(this.buildImageRow(items, containerWidth));
        }

        for(var r in rows) {
            for(var i in rows[r]) {
                var item = rows[r][i];
                thumbs.push(item);
            }
        }
        return thumbs;
    }

    handleChangeName(value) {
        this.setState({ name: value });
        if(!this.props.order.isLoggedIn) {
            //window.localStorage.setItem('commonsku_client_name', value);
        }
    }

    handleChangeMobileView(params) {
        if(params) {
            this.setState({ isImage: true, currentTab: 'gallery' });
        }else{
            this.setState({ isImage: !this.state.isImage });
        }
    }

    _onImageClick(event) {
        console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
    }

    _onImageLoad(event) {
        console.debug('loaded image', event.target.src);
    }

    _onSlide(index) {
        this._resetVideo();
        console.debug('slid to index', index);

        if(this.props.windowWidth > 640 && this.props.images[index].parent_type == 'OPTION' && this.state.currentTab == 'gallery') {
          this.setState({
              currentImage: index,
              currentItemId: this.props.images[index].item_id,
              currentItemType: this.props.images[index].parent_type,
              currentTab:  'description'
          });
        }else{
          this.setState({
              currentImage: index,
              currentItemId: this.props.images[index].item_id,
              currentItemType: this.props.images[index].parent_type,
          });
        }
    }

    _onPause(index) {
        console.debug('paused on index', index);
    }

    _onScreenChange(fullScreenElement) {
        console.debug('isFullScreen?', !!fullScreenElement);
    }

    _onPlay(index) {
        console.debug('playing from index', index);
    }

    _handleInputChange(state, event) {
        this.setState({[state]: event.target.value});
    }

    _handleCheckboxChange(state, event) {
        this.setState({[state]: event.target.checked});
    }

    _resetVideo() {
        this.setState({showVideo: {}});

        if (this.state.showPlayButton) {
          this.setState({showGalleryPlayButton: true});
        }

        if (this.state.showFullscreenButton) {
          this.setState({showGalleryFullscreenButton: true});
        }
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
          showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
          if (this.state.showPlayButton) {
            this.setState({showGalleryPlayButton: false});
          }

          if (this.state.showFullscreenButton) {
            this.setState({showGalleryFullscreenButton: false});
          }
        }
    }

    _renderVideo(item) {
        return (
          <div className='image-gallery-image'>
            {
              this.state.showVideo[item.embedUrl] ?
                <div className='video-wrapper'>
                    <a
                      className='close-video'
                      onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                    >
                    </a>
                    <iframe
                      width='560'
                      height='315'
                      src={item.embedUrl}
                      frameBorder='0'
                      allowFullScreen
                    >
                    </iframe>
                </div>
              :
                <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                  <div className='play-button'></div>
                  <img src={item.original}/>
                  {
                    item.description &&
                      <span
                        className='image-gallery-description'
                        style={{right: '0', left: 'initial'}}
                      >
                        {item.description}
                      </span>
                  }
                </a>
            }
          </div>
        );
    }

    render () {
        //var patt = new RegExp("/files/");
        var images = this.state.thumbnails.map((item, idx) => {
            //if(patt.test(item.src)) {
                //if(item.parent_type === 'OPTION') {
                    return <Image
                    key={"Image-"+idx+"-"+item.src}
                    item={item}
                    index={idx}
                    margin={this.props.margin}
                    height={item.height}
                    isSelectable={this.props.enableImageSelection}
                    onClick={this.getOnClickThumbnailFunc()}
                    onImageSelected={this.onImageSelected}
                    caption={item.caption}
                    description={item.description}
                    width={item.width}
                    top={this.props.windowWidth < 640 ? item.top + 50 : item.top + 240}
                    left={item.left}
                    quantity={this.state.cart[item.item_id] ? this.state.cart[item.item_id].total_quantity : null}
                    order={this.props.order}
                    onCreateAddToQuotePopup={this.props.onCreateAddToQuotePopup}
                        />;
                //}
            /* } else{
                return (
                    <div className="gallery-title">{item.item_name}</div>
                );
            } */
        });

        const last_div_height = this.props.windowWidth < 640 && this.state.thumbnails.length != 0 ? this.state.thumbnails[this.state.thumbnails.length - 1].top + 120 + this.state.thumbnails[this.state.thumbnails.length - 1].height :
                                this.props.windowWidth >= 640 && this.state.thumbnails.length != 0 ? this.state.thumbnails[this.state.thumbnails.length - 1].top + 240 + this.state.thumbnails[this.state.thumbnails.length - 1].height : null;

        const cascade_rep_info = {
            float: 'none',
            marginTop: '10px',
            color: '#2d424c'
        };
        const no_cascade_rep_info ={
            float: 'right',
            color: '#2d424c'
        };
        const footer_style = {
            position: 'absolute',
            top: last_div_height,
            height: '200px',
            background: '#f2f2f2',
            width: '100%',
            padding: '30px 10px 30px 10px'
        };

        let url = URI();
        let portal = url.search(true).portal;
        if(portal) {
            footer_style.marginBottom = '100px';
        }
//S40-21
        const {order,order_template}= this.props;
        const {footer_user_type} = (order_template ?? {});
        const rep = footer_user_type === 'ORDER-REP' ? (order.sales_rep ?? order.client_rep) : (order.client_rep ??order.sales_rep);

        return (
            <div>
                {this.state.lightboxIsOpen ?
                    <div>
                        <GalleryIntro
                            order={this.props.order}
                            company_avatar_url={this.props.company_avatar_url}
                            company_name={this.props.company_name}
                            username={this.props.username}
                            company_address={this.props.company_address}
                            location_url={this.props.location_url}
                            windowHeight={this.props.windowHeight}
                            handleQuote={this.props.handleQuote}
                            handleCancelQuote={this.props.handleCancelQuote}
                            in_quote={this.state.in_quote}
                            position={this.props.position}
                            user_email={this.props.user_email}
                            items={this.props.items}
                            handleFilter={this.props.handleFilter}
                            only_topbar={true}
                            closeLightbox={this.closeLightbox}
                            handleSwitchTab={this.handleSwitchTab}
                            handleChangeMobileView={this.handleChangeMobileView}
                            currentTab={this.state.currentTab}
                            currentItemType={this.state.currentItemType}
                            currentItemComments={this.state.currentItemComments}
                            windowWidth={this.props.windowWidth}
                            filter={this.props.filter}
			                client_name={this.props.client_name} />
                        {this.props.windowWidth < 640 && this.state.currentTab == 'gallery' || this.props.windowWidth >= 640 ?
                            <section className='image-gallery-section' style={portal ? {paddingBottom: '100px'} : null}>
                                <ImageGallery
                                    ref={i => this._imageGallery = i}
                                    items={this.state.images}
                                    lazyLoad={false}
                                    onClick={this._onImageClick.bind(this)}
                                    onImageLoad={this._onImageLoad}
                                    onSlide={this._onSlide.bind(this)}
                                    onPause={this._onPause.bind(this)}
                                    onScreenChange={this._onScreenChange.bind(this)}
                                    onPlay={this._onPlay.bind(this)}
                                    infinite={this.state.infinite}
                                    showBullets={this.state.showBullets}
                                    showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                                    showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                                    showThumbnails={this.state.showThumbnails}
                                    showIndex={this.state.showIndex}
                                    showNav={this.state.showNav}
                                    slideInterval={parseInt(this.state.slideInterval)}
                                    slideOnThumbnailHover={this.state.slideOnThumbnailHover}
                                    startIndex={this.state.currentImage}
                                    sendMessage={this.props.sendMessage}
                                    remoteCommand={true}
                                />
                            </section>
                        : null}
                        {this.props.order && this.props.order.view_type === 'SMART' && this.props.windowWidth < 640 && this.props.order.disable_smart == 0 && !this.props.sales_order_checked_out && this.state.currentTab === 'description'?
                          <button id="order" className="button btn alt gallery-order-button" onClick={e => {e.preventDefault(); this.props.onCreateAddToQuotePopup(this.state.currentItemId, 'OPTION');}}>
                            {this.state.cart[this.state.currentItemId] ?
                              <div><p style={{display: 'inline'}}>Added</p><div id="order-items">{this.state.cart[this.state.currentItemId].total_quantity}</div></div>
                            :
                              <div>Add to Order</div>
                            }
                          </button>
                        : null}
                        {this.props.windowWidth < 640 && this.state.currentTab == 'description' || this.props.windowWidth < 640 && this.state.currentTab == 'comment' || this.props.windowWidth >= 640 ?
                            <GalleryText
                                currentItemId={this.state.currentItemId}
                                currentItemType={this.state.currentItemType}
                                handleChangeName={this.handleChangeName}
                                name={this.state.name}
                                isImage={this.state.isImage}
                                currentTab={this.state.currentTab}
                                grabComments={this.grabComments}
                                handleQuote={this.props.handleQuote}
                                closeLightbox={this.closeLightbox}
                                windowHeight={this.props.windowHeight}
                                onCreateAddToQuotePopup={this.props.onCreateAddToQuotePopup}
                                windowWidth={this.props.windowWidth}
                                handleSwitchTab={this.handleSwitchTab} />
                        : null}
                    </div>
                :
                    <div>
                        <div id="Gallery" ref={(c) => this._gallery = c}>
                            <GalleryIntro
                                order={this.props.order}
                                company_avatar_url={this.props.company_avatar_url}
                                company_name={this.props.company_name}
                                username={this.props.username}
                                company_address={this.props.company_address}
                                location_url={this.props.location_url}
                                windowHeight={this.props.windowHeight}
                                handleQuote={this.props.handleQuote}
                                handleCancelQuote={this.props.handleCancelQuote}
                                in_quote={this.state.in_quote}
                                position={this.props.position}
                                user_email={this.props.user_email}
                                items={this.props.items}
                                handleFilter={this.props.handleFilter}
                                only_topbar={false}
                                closeLightbox={this.closeLightbox}
                                handleSwitchTab={this.handleSwitchTab}
                                handleChangeMobileView={this.handleChangeMobileView}
                                currentTab={this.state.currentTab}
                                currentItemType={this.state.currentItemType}
                                currentItemComments={this.state.currentItemComments}
                                windowWidth={this.props.windowWidth}
                                filter={this.props.filter}
                                client_name={this.props.client_name} />
                            {images}
                            {/* <Lightbox
                        images={this.props.images}
                        backdropClosesModal={this.props.backdropClosesModal}
                        currentImage={this.state.currentImage}
                        customControls={this.props.customControls}
                        enableKeyboardInput={this.props.enableKeyboardInput}
                        imageCountSeparator={this.props.imageCountSeparator}
                        isOpen={this.state.lightboxIsOpen}
                        onClickImage={this.handleClickImage}
                        onClickNext={this.gotoNext}
                        onClickPrev={this.gotoPrevious}
                        onClose={this.closeLightbox}
                        showCloseButton={this.props.showCloseButton}
                        showImageCount={this.props.showImageCount}
                        showThumbnails={this.props.showThumbnails}
                        onClickThumbnail={this.gotoImage}
                            /> */}
                            {/* portal need margin bottom 100px here */}
                            <div style={footer_style}>
                                <div style={{background: '#FFF', padding: '40px'}}>
                                    <div className="rep-info" style={{marginRight: '10px', color: '#2d424c'}}>
                                      <b style={{color: '#2d424c'}}>{ rep.contact_first_name + " " +  rep.contact_last_name } </b> {rep.contact_position ? '- '+ rep.contact_position : ''}
                                      <br /><a href={`mailto:${rep.contact_email}`} style={{color: '#5CA3B6'}}>{rep.contact_email}</a><br />
                                      {filter(rep.phones, {phone_type: 'WORK'}).map(p =>
                                        <p key={'phone_'+p.phone_id} style={{marginBottom: '0px', lineHeight: '21px'}}>{p.phone_number} {p.phone_extension != '' ? `x${p.phone_extension}` : null}</p>
                                      )}
                                    </div>
                                    {this.props.order_template?.address && this.props.resku_form ?
                                        <div className="rep-info" style={this.props.windowWidth < 640 ? cascade_rep_info : no_cascade_rep_info}>
                                            {this.props.order_template.address.address_company ?
                                                <div>{this.props.order_template.address.address_company}</div>
                                            : null}
                                            <div>{this.props.order_template.address.address_line_1} {this.props.order_template.address.address_line_2 ? `, ${this.props.order_template.address.address_line_2}` : null} {this.props.order_template.address.address_line_3 ? `, ${this.props.order_template.address.address_line_3}` : null} {this.props.order_template.address.address_line_4 ? `, ${this.props.order_template.address.address_line_4}` : null}
                                              <br />{this.props.order_template.address.address_city}, {this.props.order_template.address.address_state} {this.props.order_template.address.address_postal}, {this.props.order_template.address.address_country}
                                            </div>
                                        </div>
                                    : this.props.company_address ?
                                        <div className="rep-info" style={this.props.windowWidth < 640 ? cascade_rep_info : no_cascade_rep_info}>
                                            {this.props.company_address.address_company ?
                                                <div>{this.props.company_address.address_company}</div>
                                            : null}
                                            <div>{this.props.company_address.address_line_1} {this.props.company_address.address_line_2 ? `, ${this.props.company_address.address_line_2}` : null} {this.props.company_address.address_line_3 ? `, ${this.props.company_address.address_line_3}` : null} {this.props.company_address.address_line_4 ? `, ${this.props.company_address.address_line_4}` : null}
                                              <br />{this.props.company_address.address_city}, {this.props.company_address.address_state} {this.props.company_address.address_postal}, {this.props.company_address.address_country}
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

Gallery.displayName = 'Gallery';

Gallery.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            srcset: PropTypes.array,
            caption: PropTypes.string,
            thumbnailWidth: PropTypes.number.isRequired,
            thumbnailHeight: PropTypes.number.isRequired,
            isSelected: PropTypes.bool
        })
    ).isRequired,
    sendMessage: PropTypes.func,
    enableImageSelection: PropTypes.bool,
    onImageSelected: PropTypes.func,
    rowHeight: PropTypes.number,
    margin: PropTypes.number,
    onClickThumbnail: PropTypes.func,
    enableLightbox: PropTypes.bool,
    backdropClosesModal: PropTypes.bool,
    currentImage: PropTypes.number,
    preloadNextImage: PropTypes.bool,
    customControls: PropTypes.arrayOf(PropTypes.node),
    enableKeyboardInput: PropTypes.bool,
    imageCountSeparator: PropTypes.string,
    isOpen: PropTypes.bool,
    onClickImage: PropTypes.func,
    onClickNext: PropTypes.func,
    onClickPrev: PropTypes.func,
    onClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
    showImageCount: PropTypes.bool,
    showThumbnails: PropTypes.bool,
    handleClickSnippet: PropTypes.func,
    order: PropTypes.object,
    company_avatar_url: PropTypes.string,
    company_name: PropTypes.string,
    username: PropTypes.string,
    user_phones: PropTypes.array,
    company_address: PropTypes.object,
    position: PropTypes.string,
    user_email: PropTypes.string,
    location_url:PropTypes.string,
    grabLightboxStatus: PropTypes.func,
    windowHeight: PropTypes.number,
    handleQuote: PropTypes.func,
    handleCancelQuote: PropTypes.func,
    in_quote: PropTypes.bool,
    cart: PropTypes.object,
    items: PropTypes.array,
    handleFilter: PropTypes.func,
    onCreateAddToQuotePopup: PropTypes.func,
    windowWidth: PropTypes.number,
    filter: PropTypes.string,
    sales_order_checked_out: PropTypes.object,
    order_template: PropTypes.object,
    resku_form: PropTypes.bool,
    client_name: PropTypes.string
};

Gallery.defaultProps = {
    enableImageSelection: true,
    rowHeight: 180,
    margin: 2,
    enableLightbox: true,
    backdropClosesModal: false,
    currentImage: 0,
    preloadNextImage: true,
    enableKeyboardInput: true,
    imageCountSeparator: ' of ',
    isOpen: false,
    showCloseButton: true,
    showImageCount: true,
    showThumbnails: true
};

export default Gallery;
