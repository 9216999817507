import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getImageSrc } from '../utils';
import Toggle from './Toggle';

class GallerySnippet extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { items, index, handleClickSnippet } = this.props;

    return (
      <div className="gallery-snippet">
        {items[index].images.length <= 1 ? null : items[index].images.map(f => {
          return <Toggle key={'GallerySnippet-'+f.file_id} render={(setToggle) => {
            return <img
              key={f.file_id} className="gallery-snippet-image" src={getImageSrc(f, 'small')}
              onClick={e => {e.preventDefault(); handleClickSnippet(f, items[index].item_id);}}
              onMouseEnter={e => {e.preventDefault(); handleClickSnippet(f, items[index].item_id);}}
              onMouseLeave={e => {
                e.preventDefault(); handleClickSnippet(items[index].default_file, items[index].item_id);
              }}
              onError={() => {
                setToggle(false);
              }}
            />;
          }}/>;
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GallerySnippet);
