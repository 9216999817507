import { get } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledSelect from './LabelledSelect';

import { createSmartCheckout, createAddComment } from '../actions';
import { createCartSummaryPopup } from '../actions/popup';

import { parseMysqlDate } from '../utils';

class GalleryIntro extends Component {

  constructor(props) {
    super(props);

    this.state = {
      full_intro: false,
      items: props.cart,
      receiver_email: '',
      comment: '',
      in_quote: props.in_quote,
      finish_checkout: false,
      filter: props.filter,
      filter_left: 0,
      windowWidth: props.windowWidth,
      titles_style: 'abs',
      show_right_nav: false,
      type_widths: []
    };

    this.handleToggleExpandIntro = this.handleToggleExpandIntro.bind(this);
    this.handleChangeReceiverEmail = this.handleChangeReceiverEmail.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.translateFilter = this.translateFilter.bind(this);
    this.handleTitlesWidth = this.handleTitlesWidth.bind(this);
  }

  componentDidMount() {
    this.handleTitlesWidth();
    let type_widths = [];
    let patt = new RegExp("type_");
    for(let value in this) {
      if(patt.test(value)) {
        type_widths.push(this[value].getBoundingClientRect().width);
      }
    }
    this.setState({ type_widths: type_widths });

    let titles_width = this.titles ? this.titles.getBoundingClientRect().width : null;
    /* for(let i = 0; i < this.state.type_widths.length; i++) {
      if(titles_width > this.state.type_widths[i]) {
        titles_width - this.state.types_widths[i]
      }else if(titles_width < this.state.type_widths[i]) {
        this.setState({ index: i }, function() {
          console.log(this.state.index);
          console.log(titles_width);
        })
        break;
      }
    } */
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ items: nextProps.cart, in_quote: nextProps.in_quote, windowWidth: nextProps.windowWidth, filter: nextProps.filter });
    if(nextProps.windowWidth != this.state.windowWidth) {
      this.setState({ titles_style: 'abs' }, function() {
        this.handleTitlesWidth();
      });
    }
  }

  handleTitlesWidth() {
    let title_container_width = this.title_container ? this.title_container.getBoundingClientRect().width : null;
    let titles_width = this.titles ? this.titles.getBoundingClientRect().width : null;

    if(title_container_width && titles_width && titles_width > title_container_width) {
      this.setState({ titles_style: 'relative_left', show_right_nav: true });
    }else if(title_container_width && titles_width && titles_width <= title_container_width) {
      this.setState({ titles_style: 'relative_center', show_right_nav: false });
    }
  }

  handleToggleExpandIntro() {
    this.setState({ full_intro: !this.state.full_intro });
  }

  handleChangeReceiverEmail(value) {
    this.setState({ receiver_email: value });
  }

  handleChangeComment(value) {
    this.setState({ comment: value });
  }

  onFilter(filter) {
    if(!filter) {
      this.props.handleFilter(null);
    }else{
      this.props.handleFilter(filter);
    }
  }

  translateFilter(direction) {
    if(direction == 'left') {
      this.setState({ filter_left: this.state.filter_left - 15 });
    }else if(direction == 'right') {
      this.setState({ filter_left: this.state.filter_left + 15 });
    }
  }

  render() {
    const {
      types,
      types_dropdown,
      banner_url,
      job_name,
      cart_total_items,
      sales_rep_avatar,
      sales_order_checked_out,
      order_template,
      resku_form,
      order,
      company_avatar_url,
      company_name,
      username,
      company_address,
      position,
      user_email,
      location_url,
      windowHeight,
      handleQuote,
      handleCancelQuote,
      in_quote,
      onCreateSmartCheckout,
      handleFilter,
      onCreateCartSummaryPopup,
      only_topbar,
      closeLightbox,
      handleSwitchTab,
      handleChangeMobileView,
      currentTab,
      currentItemType,
      currentItemComments,
      windowWidth,
      filter,
      client_name
    } = this.props;
    const font_14 = {
      fontSize: '14px'
    };
    const gallery_banner = {
      backgroundImage: 'url(' + banner_url + ')',
      height: '240px',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      //backgroundColor: '#fefefe',
      textAlign: 'center',
      fontSize: '40px',
      marginTop: '130px'
    };
    const filter_active = {
      background: '#5CA3B6',
      color: 'white',
      borderRadius: '40px',
      position: 'relative',
      left: `${this.state.filter_left}%`
    };
    const filter_inactive = {
      position: 'relative',
      left: `${this.state.filter_left}%`
    };
    const fixed_topbar = {
      zIndex: 10000,
      width: '100%'
    };
    const view_all_filter_active = {
      background: '#5CA3B6',
      color: 'white',
      borderRadius: '40px',
      padding: '10px 20px 10px 20px',
      position: 'relative',
      whiteSpace: 'nowrap'
    };
    const view_all_filter_inactive = {
      position: 'relative',
      whiteSpace: 'nowrap',
      background: '#FFF',
      color: '#2d424c',
      borderRadius: '40px',
      padding: '10px 20px 10px 20px'
    };
    const abs = {
      position: 'absolute'
    };
    const relative_left = {
      position: 'relative',
      textAlign: 'left'
    };
    const relative_center = {
      position: 'relative',
      textAlign: 'center'
    };
    const topbar_style = order_template && resku_form ? {
      background: 'url("' + get(order_template, 'header.file_name_headers', '') + '")',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    } : null;

    return <div className="gallery-intro" style={only_topbar ? fixed_topbar : null}>
        <div id="topbar" style={topbar_style}>
          <div className="row" style={{ maxWidth: "none" }}>
            <div className="small-1 columns com-logo">
              <img src={order_template && order_template.avatar && resku_form ? order_template.avatar.medium : company_avatar_url} className="logo" />
            </div>
            <div className="rep-img hide-for-small-only">
              <div className="rep-info" style={order_template && order_template.order_header_font_color_id ? { color: order_template.order_header_font_color_id, margin: "15px 0px 0px 20px" } : { margin: "15px 0px 0px 20px" }}>
                <span style={order_template && order_template.order_header_font_color_id ? { color: order_template.order_header_font_color_id, fontSize: "32px" } : { fontSize: "32px", color: "#fff" }}>
                  {order_template && resku_form && order_template.order_header_name != "" ? order_template.order_header_name : company_name}
                </span>
                <br />
                <div>
                  <span>
                    {order.order_type} #{order.form_number} for {client_name}
                  </span>
                </div>
                <div>
                  <span>{job_name}</span>
                </div>
              </div>
            </div>
            {order.view_type === "SMART" && !sales_order_checked_out && order.disable_smart == 0 ? <div id={cart_total_items > 0 ? "order" : "order-no"} onClick={cart_total_items > 0 ? onCreateCartSummaryPopup : null}>
                <div style={{ display: "inline" }}>
                  {this.state.windowWidth < 640 ? (
                    <i
                      className="fi-shopping-cart"
                      style={{ fontSize: "25px" }}
                    />
                  ) : (
                    <div style={{ display: "inline" }}>Order</div>
                  )}
                </div>
                <div
                  id={
                    cart_total_items > 0 ? "order-items" : "order-no-items"
                  }
                >
                  {cart_total_items}
                </div>
              </div> : null}
            {order.disable_smart == 1 && sales_order_checked_out ? <div className={this.state.windowWidth < 640 ? "small-7 columns checked-out" : "checked-out"}>
                <div>
                  Order placed on{" "}
                  {parseMysqlDate(sales_order_checked_out.date_created)}
                </div>
                <div>
                  by <span style={{ color: "white" }}>
                    {sales_order_checked_out.checked_out_by}
                  </span>
                </div>
              </div> : null}
          </div>
        </div>
        {only_topbar ? <div className="row" style={{ maxWidth: "none", background: "white", position: "fixed", top: "130px", width: "100%", zIndex: 1002 }}>
            {this.state.windowWidth < 640 ? <div>
                <button className={currentTab == "comment" ? "button text-center comment-switch active" : "button text-center comment-switch"} disabled={currentItemType != "OPTION" ? true : false} onClick={e => {
                    e.preventDefault();
                    handleSwitchTab("comment");
                  }}>{`comment (${currentItemComments.length})`}</button>
                <button className={currentTab == "description" ? "button text-center description-switch active" : "button text-center description-switch"} disabled={currentItemType == "AVATAR" || currentItemType == "OPTION" ? false : true} onClick={e => {
                    e.preventDefault();
                    handleSwitchTab("description");
                  }}>
                  info
                </button>
                <button className={currentTab == "gallery" ? "button text-center gallery-switch active" : "button text-center gallery-switch"} onClick={e => {
                    e.preventDefault();
                    handleChangeMobileView("gallery");
                  }} disabled={this.state.windowWidth < 640 ? false : true}>
                  gallery
                </button>
              </div> : null}
            <a className="button close-gallery" onClick={closeLightbox}>
              <i className="fi-x" />
            </a>
          </div> : null}
        {!only_topbar && types.length > 0 && this.state.windowWidth >= 640 ? <div style={{ marginTop: "130px" }}>
            <div className="small-1 columns" style={{ paddingTop: "35px" }}>
              {/* <a href="javascript:void(0);" className="gallery-filter" onClick={e => {e.preventDefault(); this.onFilter(null)}} style={this.state.filter === '' ? view_all_filter_active : view_all_filter_inactive}>View All</a> */}
              {this.state.filter_left < 0 ? <a href="javascript:void(0)" onClick={e => {
                    e.preventDefault();
                    this.translateFilter("right");
                  }} className="gallery-direction">
                  &lsaquo;
                </a> : <div>&nbsp;</div>}
            </div>
            <div ref={ref => {
                this.title_container = ref;
              }} className="gallery-filter-group small-10 columns">
              <div ref={ref => {
                  this.titles = ref;
                }} style={this.state.titles_style == "abs" ? abs : this.state.titles_style == "relative_left" ? relative_left : this.state.titles_style == "relative_center" ? relative_center : null}>
                {types.map((t, idx) => <a key={t.item_id} ref={ref => {
                      this[`type_${idx}`] = ref;
                    }} href="javascript:void(0);" className="gallery-filter" onClick={e => {
                      e.preventDefault();
                      this.state.filter === t.item_id ? this.onFilter(null) : this.onFilter(t.item_id);
                    }} style={this.state.filter === t.item_id ? filter_active : filter_inactive}>
                    <span>{t.item_name}</span>
                    {this.state.filter === t.item_id ? <span
                        style={{ fontSize: "17px" }}
                      >
                        &nbsp;&nbsp;&times;
                      </span> : null}
                  </a>)}
              </div>
            </div>
            <div className="small-1 columns" style={{ paddingTop: "35px" }}>
              {this.state.filter_left <= 0 && this.state.show_right_nav ? <a href="javascript:void(0)" onClick={e => {
                    e.preventDefault();
                    this.translateFilter("left");
                  }} className="gallery-direction" style={{ float: "right" }}>
                  &rsaquo;
                </a> : <div>&nbsp;</div>}
            </div>
          </div> : !only_topbar && types_dropdown.length > 0 && this.state.windowWidth < 640 ? <div style={{ marginTop: "130px", padding: "5px 0px 0px 5px" }}>
            <LabelledSelect label="" options={types_dropdown} value={this.state.filter} onChange={e => this.onFilter(e)} gallery={true} placeholder="View All" />
          </div> : null}
      </div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const cart = state.temp;
  const banner_url = `/files/${state.header.hash}/headers/${state.header.file_name}`;
  const types = ownProps.items.filter(i => i.parent_type === 'SEPARATOR').map(i => ({ item_id: i.item_id, item_name: i.item_name }));
  const types_dropdown = [{key: '', value: 'View All'}].concat(ownProps.items.filter(i => i.parent_type === 'SEPARATOR').map(i => ({ key: i.item_id, value: i.item_name })));
  const job_name = state.job.job_name;
  const cart_total_items = Object.values(state.temp).map(t => parseFloat(t.total_quantity) || 0).reduce((a, b) => a + b, 0);
  const sales_rep_avatar = state.sales_rep.avatar ? state.sales_rep.avatar.medium : null;
  const sales_order_checked_out = state.sales_order_checked_out ? state.sales_order_checked_out : null;
  const order_template = state.order_template ? state.order_template : null;
  const resku_form = state.resku_form;

  return {
    cart,
    banner_url,
    types,
    types_dropdown,
    job_name,
    cart_total_items,
    sales_rep_avatar,
    sales_order_checked_out,
    order_template,
    resku_form
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddComment: (item_id, commenter_name, comment) => {
      dispatch(createAddComment(item_id, null, commenter_name, null, comment));
    },
    onCreateSmartCheckout: (items, job_id, parent_id, target_type, sales_rep_id, receiver_email, comment, order_number, order_id) => {
      dispatch(createSmartCheckout(items, job_id, parent_id, target_type, sales_rep_id, receiver_email, comment, order_number, order_id));
    },
    onCreateCartSummaryPopup: () => {
      dispatch(createCartSummaryPopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryIntro);
